import {
  Component,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';

import JSMpeg from 'jsmpeg-player';

import { HttpService } from '../../services/http.service';
import { GlobalService } from '../../services/global.service';



@Component({
  selector: 'app-allcameras',
  templateUrl: './allcameras.component.html',
  styleUrls: ['./allcameras.component.scss']
})
export class AllcamerasComponent implements OnInit {


  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('videoContainer', { static: true })

  videoContainer: ElementRef<HTMLDivElement>;

  player: any;
  gridColumns = 3;
  
  constructor(private _http: HttpService, public global: GlobalService) {

  }

  ngOnInit(): void {
/*

    let allcanvas = [];
    

    for (let viewindex = 0; viewindex < 2; viewindex++) {
      console.log("image = ",viewindex)
      let videoCanvas = document.createElement('canvas');
      videoCanvas.setAttribute("id", "canvas");
      //videoCanvas.setAttribute("style", "display: block; width: 80%;")
      videoCanvas.width = 200;
      //videoCanvas.height = 100;
      let cx: CanvasRenderingContext2D;
      cx = videoCanvas.getContext('2d')!;

      allcanvas.push(videoCanvas)

      this.videoContainer.nativeElement.appendChild(allcanvas[viewindex])




      let image = new Image();

      cx.lineWidth = 3;
      cx.lineCap = 'round';
      cx.strokeStyle = '#000';
      image.onload = () => {
        cx.drawImage(image, 0, 0, 200, 100);
      }

      image.src = "assets/images/carmel.JPG";

    }
*/

  }





}
