<div>
    <p>&nbsp;</p>
    <p class="center">&nbsp;

        This is a test

    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
</div>

<div class="center">
    <div style="width: 80%;">
        <div class="table-inner-wrapper mat-elevation-z8 ">
            <table mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef> location </th>
                    <td mat-cell *matCellDef="let element"> {{element.location}} </td>
                </ng-container>

                <ng-container matColumnDef="fileName">
                    <th mat-header-cell *matHeaderCellDef> File Name </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="file-links" (click)="onClickFile(element.fileName)"
                            *ngIf="element.fileName !== ''; else noFile">
                            {{ element.fileName }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="startTime">
                    <th mat-header-cell *matHeaderCellDef> startTime </th>
                    <td mat-cell *matCellDef="let element"> {{element.startTime }} </td>
                </ng-container>

                <ng-container matColumnDef="stopTime">
                    <th mat-header-cell *matHeaderCellDef> sopTime </th>
                    <td mat-cell *matCellDef="let element"> {{element.stopTime }} </td>
                </ng-container>


                <ng-container matColumnDef="totalFrames">
                    <th mat-header-cell *matHeaderCellDef> Total frames </th>
                    <td mat-cell *matCellDef="let element"> {{element.totalFrames}} </td>
                </ng-container>

                <ng-container matColumnDef="link">
                    <th mat-header-cell *matHeaderCellDef> Symbol </th>
                    <td mat-cell *matCellDef="let element"> {{element.link}} </td>
                </ng-container>

                <!-- No-Data Found-->
                <ng-container matColumnDef="empty-row">
                    <td mat-cell *matCellDef="let element" colspan="9" class="empty-row">
                        <div class="empty-row-container">
                            <div class="empty-row-text">No Data Found</div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>



                <tr mat-row *matRowDef="
                  let row;
                  columns: dataSource.data.length > 0 &&
                  dataSource.filteredData.length > 0
                    ? displayedColumns
                    : ['empty-row'];
                  let i = index
                " (click)="clickTableRow(i, row)" #tableRows></tr>


            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons aria-label="Select page"
                class="mat-elevation-z7 paginator">
            </mat-paginator>
        </div>
    </div>
</div>



<div class="modal fade" id="videoModal" tabindex="-1" data-backdrop="true" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true" (click)="backdropClicked($event, videoModal)"
    #videoModal>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content popup-animation">
            <div class="modal-header">
                <p class="modal-title" id="exampleModalCenterTitle" style="font-size: 11px; text-transform: uppercase"
                    translate>
                    Video
                </p>
                <button style="color: grey" type="button" class="close" (click)="closeModal(videoModal)">
                    <span aria-hidden="true" style="color: grey">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="video-container center-item">
                    <video [src]="videoUrl" *ngIf="videoUrl && !fileNotFoundError" controls="true" autoplay loop
                        playsinline width="100%" poster="../../assets/images/YO-logo.png" class="video-background"
                        type='video/mp4'>
                    </video>
                </div>
                <div class="loading-container center-item" *ngIf="!videoUrl && !fileNotFoundError">
                    <div class="video-spinner">
                        <div class="double-bounce1"></div>
                        <div class="double-bounce2"></div>
                    </div>
                </div>
                <div class="file-error-container" *ngIf="fileNotFoundError && !videoUrl">
                    <div class="error-image">
                        <img src="" alt="File Not Found" style="height: 100%; width: auto" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>