import {
  Component,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';

import JSMpeg from 'jsmpeg-player';

import { HttpService } from '../../services/http.service';
import { GlobalService } from '../../services/global.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-allzones',
  templateUrl: './allzones.component.html',
  styleUrls: ['./allzones.component.scss']
})
export class AllzonesComponent implements OnInit {



  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('videoContainer', { static: true })

  videoContainer: ElementRef<HTMLDivElement>;

  player: any;
  gridColumns = 3;
  zones = this.global.zones;
  


  constructor(private router: Router, private _http: HttpService, public global: GlobalService) { 
   
    console.log("User from global : ", this.global.userInfo)
    console.log("zones from global : ", this.global.zones)
    console.log("cameras from global : ", this.global.cameras)

  }

  ngOnInit(): void {
  }

  selectZone(zone) {
    console.log("Zone selected : ",zone)
    this.global.selectZone(zone)

    this.router.navigate(['/onezone'])
  }



  onFileSelected(event,zone) {

    console.log("User from global : ", this.global.userInfo)
    console.log("selected zone : ", zone)

   if (this.global.userInfo.data.siteID != undefined) {
    this._http.onFileSelected(event,this.global.userInfo.data.siteID,zone.id)
   }
  

  }

}
