import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';

import { HttpService } from './../services/http.service';
import { GlobalService } from './../services/global.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup | any;
  title = 'Cameralogin';
  loginerror = "";


  constructor(private router: Router, private _http: HttpService, public global: GlobalService) {
    /*
        this.loginForm = new FormGroup({
          email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(
            '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,63}$',
          ),]),
          password: new FormControl('', [Validators.required, Validators.pattern(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$'
          )])
        });
    */

    this.loginForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl('')
    });




  }

  ngOnInit(): void {

  }


  onSubmit() {
    if (!this.loginForm.valid) {
      return;
    }

    this.initiateglobalData()


  }


  initiateglobalData() {

    console.log("Starting the login process", this.loginForm.value.email)

    let email = this.loginForm.value.email

    this._http.getFilterCollectionData('videoUsers', 'email', email).then(async (snapshot: any) => {
      if (snapshot.docs != undefined) {
        if (snapshot.docs.length > 0) {
          let currentVideoUser = snapshot.docs[0].data()
          if (this.loginForm.value.password == currentVideoUser.password) {
            this.global.userInfo = { id: snapshot.docs[0].id, data: currentVideoUser }
            console.log("User Info = ", this.global.userInfo)

            let zonepath = "videoSites/" + currentVideoUser["siteID"] + "/Zones";
            let camerapath = "videoSites/" + currentVideoUser["siteID"] + "/cameras";

            await this._http.getCollectionList(zonepath).then(async (zonedata: any) => {
              this.global.zones = zonedata
              console.log("Zone Info = ", this.global.zones)
            })

            await this._http.getCollectionList(camerapath).then(async (camerasdata: any) => {
              this.global.cameras = camerasdata
              this.global.setCamerasDictionary(camerasdata)
              console.log("Cameras Info = ", this.global.cameras)
            })


            


            localStorage.setItem('user', JSON.stringify(this.global.userInfo))
            localStorage.setItem('zones', JSON.stringify(this.global.zones))
            localStorage.setItem('cameras', JSON.stringify(this.global.cameras))

            this.router.navigate(['/allzones'])

          } else {
            console.log("password error")
            this.loginerror = "password error"
            return;
          }
        } else {

          console.log("User doen't exist")
          this.loginerror = "user does not exist"
          return;
        }

      } else {
        console.log("User doesn't exist")
        this.loginerror = "user does not exist"
        return;
      }
    });


  }





}
