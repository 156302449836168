import { Injectable, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { HttpService, DataUpdateEvent } from './http.service';

interface Dictionary<T> {
  [Key: string]: T;
}


export class AllCameras {
  camera: Dictionary<string> = {};
}


export class MyServiceEvent {
  command: string;
  value: any;
  data?: any = '';
}


@Injectable({
  providedIn: 'root'
})



export class GlobalService {


  onChange: EventEmitter<MyServiceEvent> = new EventEmitter<MyServiceEvent>();

  public camerasD: Dictionary<string> = {}

  public userInfo: any;
  public zones: any;
  public selectedZone: any;
  public cameras: any;
  public recordingInProgress = false;
  public isRecordingAvailable = false;
  public zoneCameras: any;
  constructor(private _http: HttpService) {


    let user = localStorage.getItem("user")

    if ((user != undefined) && (this.userInfo == undefined)) {

      this.userInfo = JSON.parse(user)

      this._http.getCollectionData("videoUsers", this.userInfo.id).then(async (snapshot: any) => {
        if (snapshot.docs != undefined) {
          if (snapshot.docs.length > 0) {
            let currentVideoUser = snapshot.docs[0].data()
            if (currentVideoUser.recordingInProcess != undefined) {
              this.recordingInProgress = currentVideoUser.recordingInProcess
            }
            console.log("Recording status = ", this.recordingInProgress)
          }
        }
      });


      try {
        this.zones = JSON.parse(localStorage.getItem("zones"))
      }
      catch {
        console.log("Error parsing zones")
      }

      try {
        this.cameras = JSON.parse(localStorage.getItem("cameras"))
      } catch {
        console.log("Error parsing cameras")
      }

      try {
        this.selectedZone = JSON.parse(localStorage.getItem("selectedzone"))
      } catch {
        console.log("Error parsing zone")
      }

      if (this.cameras != undefined) {
        this.setCamerasDictionary(this.cameras);
      }

    }
  }

  getCamerabyID(cameraID) {
    return this.camerasD[cameraID]
  }


  setCamerasDictionary(cameras: any) {

    for (let camera of cameras) {
      this.camerasD[camera.id] = camera.data;
    }

    console.log("Set camera Dirctionary : ", this.camerasD)
    localStorage.setItem('camerasD', JSON.stringify(this.camerasD))

  }

  public selectZone(zone) {

    this.selectedZone = zone;
    localStorage.setItem('selectedzone', JSON.stringify(zone))

    this.zoneCameras = [];
    for (let cameraIndex in this.selectedZone.data.cameras) {
      this.zoneCameras.push(this.getCamerabyID(this.selectedZone.data.cameras[cameraIndex]))
    }

  }

  public StopRecording() {

    this.recordingInProgress = false
    console.log("Stop recording ")

    //this.recordEvent.emit(false);
    this.setDisableRecording(false);
    this.setRecordingInProgress(false)
  }


  public setDisableRecording(isDisable: boolean) {
    this.onChange.emit({ command: "isDisable", value: isDisable });
    this.isRecordingAvailable = !isDisable
  }

  public setRecordingInProgress(isRecording: boolean) {
    this.onChange.emit({ command: "isRecording", value: isRecording });
    this.recordingInProgress = isRecording
  }

  public StartRecording() {

    //console.log("Selected Court data ", this.selectedCourtData)
    console.log("Start the recording ", this.selectedZone)
    console.log("Zone camera ", this.zoneCameras)
    this.setDisableRecording(true);
    this.setRecordingInProgress(true)

    let data = {
      "siteID": this.userInfo.data.siteID,
      "userID": this.userInfo.data.userID,
      "cameraID": this.zoneCameras[0].id,
      "rtspurl": 'rtsp://' + this.zoneCameras[0].link,
      "videoLength": this.userInfo.data.videoLength,
    }

    console.log("Data for recording ", data)

    this._http.startRecord(data).subscribe(
      res => {
        console.log("  Results: completed", res);

        this.StopRecording()
        //this.toggle = true
        //this.isDisabled = false;

      },
      err => {
        console.log("Error occured in function ", err);

        this.StopRecording()
        //this.toggle = true
        //this.isDisabled = false;

      }


    );




  }



}
