import {
  Component,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';

import JSMpeg from 'jsmpeg-player';

import { HttpService, DataUpdateEvent } from './../services/http.service';
import { GlobalService } from './../services/global.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('videoContainer', { static: true })
  videoContainer: ElementRef<HTMLDivElement>;


  courts = [];
  player: any;
  selectedCourtData: any;
  private ctx: CanvasRenderingContext2D;

  private _dataupdateSubscription;
  toggle = true;
  isDisabled = false;

  constructor(private _http: HttpService, public global: GlobalService) {


    this._dataupdateSubscription = this._http.onTestDataChange.subscribe({
      next: (event: DataUpdateEvent) => {


        console.log("Database event eas captured", event.data)

        switch (event.changeType) {

          case 'added':
            console.log(event.data)
            break;

          case 'modified':
            console.log("Status modified ", event.data)
            break;

          case 'removed':
            break;

        }

      }

    })



    console.log("User from global : ", this.global.userInfo)
    console.log("zones from global : ", this.global.zones)
    console.log("cameras from global : ", this.global.cameras)


    for (let zoneindex in this.global.zones) {

      console.log(this.global.zones[zoneindex].data.name)

    }

    for (let cameraindex in this.global.cameras) {
      console.log(this.global.cameras[cameraindex].data.link)

      this.courts.push({

        name: this.global.cameras[cameraindex].data.location,
        value: "1",
        link: this.global.cameras[cameraindex].data.link,
        cameraID: this.global.cameras[cameraindex].id


      })


    }

  }




  ngDoCheck(): void {
  }

  ngAfterContentInit(): void {
  }

  ngOnInit(): void {


    console.log(" The url = ", this.courts[0].link)
    let url = encodeURIComponent('admin:rwc475585@62.219.146.153:554/Streaming/Channels/502')
    //let url = encodeURIComponent(this.courts[0].link)
    this.player = new JSMpeg.Player('wss://rtspserver.mes-ltd.com:443/stream/' + url, { canvas: document.getElementById('canvas'), autoplay: true, audio: false, loop: true })
    //this.videoContainer = document.getElementById('videoContainer')
    //this.ctx = this.canvas.nativeElement.getContext('2d');



  }

  SelectCamera(selectedCourt) {

    let court = selectedCourt.value
    let url = encodeURIComponent(court.link)

    this.selectedCourtData = court;

    console.log("Court details = ", url)

    if (this.player) {
      this.player.destroy();
    }

    let videoCanvas = document.createElement('canvas');
    videoCanvas.setAttribute("id", "canvas");
    videoCanvas.setAttribute("style", "display: block; width: 60%;")
    let videoContainer = document.getElementById('videoContainer')

    videoContainer.appendChild(videoCanvas);


    this.player = new JSMpeg.Player('wss://rtspserver.mes-ltd.com:443/stream/' + url, { canvas: videoCanvas, autoplay: true, audio: false, loop: true })
  }



  StartRecord() {

    //console.log("Selected Court data ", this.selectedCourtData)
    //this.global.getCamerabyID(this.selectedCourtData.cameraID)

    console.log("Button status disable", this.isDisabled)

    if (this.isDisabled == false) {

      this.toggle = false;
      this.isDisabled = true;

      let data = {
        "siteID": this.global.userInfo.data.siteID,
        "userID": this.global.userInfo.data.userID,
        "cameraID": this.selectedCourtData.cameraID,
        "rtspurl": 'rtsp://' + this.selectedCourtData.link,
        "videoLength": 15,
      }


      console.log("Data for recording ", data)


      this._http.startRecord(data).subscribe(
        res => {
          console.log("  Results: completed", res);

          this.toggle = true
          this.isDisabled = false;

        },
        err => {
          console.log("Error occured in function ", err);

          this.toggle = true
          this.isDisabled = false;

        }


      );


    } else {
      console.log("Button is disable")
    }

  }

  StopRecord() {


  }


}
