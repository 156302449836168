import { Component, ViewChild, Input } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { delay, filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { HttpService, DataUpdateEvent } from './services/http.service';
import { GlobalService, MyServiceEvent } from './services/global.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  private _serviceSubscription;

  title = 'View Interface';

  isRecordingDisable = true
  isRecordingInProgress = false

  constructor(private _http: HttpService, public global: GlobalService, private observer: BreakpointObserver, private router: Router) {


    this._serviceSubscription = this.global.onChange.subscribe({
      next: (event: MyServiceEvent) => {

        switch (event.command) {

          case 'isRecording':
            this.isRecordingInProgress = event.value;
            break;

          case "isDisable":
            this.isRecordingDisable = event.value;
            console.log("Recoring os enable ", event.value);
            break;
        }

      }

    })



  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this._serviceSubscription.unsubscribe()
    console.log("Unsubscribe Comm service from [Analysis]");
  }


  startRecordindAZone() {
    console.log("Start the recording ", this.global.userInfo,
      "Is recording in Progress ?", this.global.recordingInProgress
    )
    this.global.StartRecording()


  }

  ngAfterViewInit() {
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        if (this.sidenav.mode === 'over') {
          this.sidenav.close();
        }
      });
  }

}
