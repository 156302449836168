import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allsites',
  templateUrl: './allsites.component.html',
  styleUrls: ['./allsites.component.scss']
})
export class AllsitesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
