

<div class="text-center">
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
    <h4 class="text-center"> Login form </h4>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate class="d-flex-center">
      <div class="example-container" >
        <div>
          <mat-form-field >
            <input matInput placeholder="Email" formControlName="email">
            <mat-error *ngIf="!loginForm.get('email')?.errors?.required && (loginForm.get('email')?.errors?.pattern)">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="loginForm.get('email')?.invalid && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched) && loginForm.get('email')?.errors?.required">
              Email is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mt-top-2">
          <mat-form-field>
            <input matInput type="password" placeholder="Password" formControlName="password">    
            <mat-error *ngIf="!loginForm.get('password')?.errors?.required">
              Password length must be greater than or equal to 8 and password must contain one or more uppercase , lowercase , numeric and special characters
            </mat-error>
            <mat-error *ngIf="loginForm.get('password')?.invalid && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched) && loginForm.get('password')?.errors?.required">
              Password is required
            </mat-error>
          </mat-form-field>
        </div>
        <div>{{loginerror}}</div>
        <div class="mt-top">
          <button [disabled]="!loginForm.valid" mat-raised-button color="primary" mat-button>Sign In</button>
        </div>
      </div>
    </form>
  </div>
