
<div class="content">
    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="card-container">
        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let camera of zoneCameras;let indexOfelement=index;" class="onecard">
            <mat-card class="mat-elevation-z4">
                <mat-card-header class="title_center">
                    <mat-card-title>{{camera.location}}
                    </mat-card-title>
                </mat-card-header >
                
                <mat-card-content class="title_center">
                    <canvas #canvas id="{{'canvas'+indexOfelement}}" style="display: block; width: 100%;border: 1;"></canvas>  
                </mat-card-content>

            </mat-card>
        </div>
    </div>
</div>

<!-- 
<div>
    <canvas #canvas id="canvas" ></canvas>  
</div>

-->

<!--{{'canvas'+num}}-->