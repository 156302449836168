import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllcamerasComponent } from './allcameras/allcameras.component'
import { AllsitesComponent } from './allsites/allsites.component'
import { MyvideosComponent } from './myvideos/myvideos.component'
import { AllzonesComponent } from './allzones/allzones.component'
import { OnezoneComponent  } from './onezone/onezone.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'allzones',
    pathMatch: 'full'
  },  
  {
    path: 'onezone',
    component: OnezoneComponent,
  },  
  {
    path: 'allzones',
    component: AllzonesComponent,
  },  
  {
    path: 'allcameras',
    component: AllcamerasComponent,
  },  
  {
    path: 'myvideos',
    component: MyvideosComponent,
  },
  {
    path: 'allsites',
    component: AllsitesComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ViewRoutingModule { }
