import {
  Component,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';

import JSMpeg from 'jsmpeg-player';

import { HttpService, DataUpdateEvent } from '../../services/http.service';
import { GlobalService } from '../../services/global.service';


@Component({
  selector: 'app-onezone',
  templateUrl: './onezone.component.html',
  styleUrls: ['./onezone.component.scss']
})
export class OnezoneComponent implements OnInit {


  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('videoContainer', { static: true })
  videoContainer: ElementRef<HTMLDivElement>;


  selectedZone: any;
  zoneCameras = []
  player: any;
  selectedCourtData: any;
  private ctx: CanvasRenderingContext2D;

  private _dataupdateSubscription;
  toggle = true;
  isDisabled = false;



  constructor(private _http: HttpService, public global: GlobalService) {


    this._dataupdateSubscription = this._http.onTestDataChange.subscribe({
      next: (event: DataUpdateEvent) => {


        console.log("Database event eas captured", event.data)

        switch (event.changeType) {

          case 'added':
            console.log(event.data)
            break;

          case 'modified':
            console.log("Status modified ", event.data)
            break;

          case 'removed':
            break;

        }

      }

    })


    this.selectedZone = this.global.selectedZone;

    for (let cameraIndex in this.selectedZone.data.cameras) {
      console.log("selected cameras", this.selectedZone.data.cameras[cameraIndex])
      this.zoneCameras.push(this.global.getCamerabyID(this.selectedZone.data.cameras[cameraIndex]))
    }

    console.log("User from global : ", this.global.userInfo)
    console.log("zones from global : ", this.global.zones)
    console.log("cameras from global : ", this.global.cameras)

    this.global.setDisableRecording(false)

    console.log("Zone Cameras : ", this.zoneCameras)


  }

  public ngOnDestroy() {
    this.global.setDisableRecording(true)
  }


  ngAfterViewInit() {

    let url = ""
    //let url = encodeURIComponent('admin:rwc475585@62.219.146.153:554/Streaming/Channels/502')

    for (let cameraIndex in this.zoneCameras) {
      url = encodeURIComponent(this.zoneCameras[cameraIndex].link)
      this.player = new JSMpeg.Player('wss://rtspserver.mes-ltd.com:443/stream/' + url, { canvas: document.getElementById('canvas' + cameraIndex), autoplay: true, audio: false, loop: true })
    }

  }

  ngOnInit(): void {

  }

  StartRecord() { }

}
