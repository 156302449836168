<mat-toolbar id='maintoolbar' class="mat-elevation-z8">
    
  <button
      mat-icon-button
      *ngIf="sidenav.mode === 'over'"
      (click)="sidenav.toggle()"
    >
      <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
      <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
    </button>
    <!-- Responsive side navigation
    
    [disabled]="(!global.isRecordingAvailable || global.recordingInProgress)"

    -->
    
    <button #recordbutton id="recordbutton"  mat-icon-button 
    (click)="startRecordindAZone()"   [disabled]="isRecordingDisable"
    >
      <mat-icon>fiber_smart_record</mat-icon>      
    </button>
    <p class="color_red" *ngIf="isRecordingInProgress">
      Recording in progress
    </p>
    <span class="title-center">{{title}}</span>
  </mat-toolbar>
  
  

  <mat-sidenav-container>
    <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8">
      <img
        class="avatar mat-elevation-z8"
        src="assets/images/carmel.JPG" 
      />
  
      <h4 class="name">Carmel Club</h4>
      <p class="designation">Tennis club</p>
  
      <mat-divider></mat-divider>
  

      <button mat-button class="menu-button" routerLink="/login" (click)="sidenav.toggle()">
        <mat-icon>login</mat-icon>
        <span>login</span>
      </button>
 
      <button mat-button class="menu-button" routerLink="/allzones" (click)="sidenav.toggle()">
        <mat-icon>camera_enhance</mat-icon>
        <span>All zones</span>
      </button>
      <button mat-button class="menu-button" routerLink="/myvideos" (click)="sidenav.toggle()">
        <mat-icon>camera_enhance</mat-icon>
        <span>View my records</span>
      </button>
  
      <mat-divider></mat-divider>
  
      <button mat-button class="menu-button" routerLink="/allcameras">
        <mat-icon>help</mat-icon>
        <span>Help</span>
      </button>
      <button mat-button class="menu-button" routerLink="/home">
        <mat-icon>home</mat-icon>
        <span>Home</span>
      </button>
      <button mat-button class="menu-button" routerLink="/allcameras">
        <mat-icon>camera_enhance</mat-icon>
        <span>View all</span>
      </button>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="content mat-elevation-z8">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  