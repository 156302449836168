<div class="content">
    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="card-container">
        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let zone of zones" class="onecard">
            <mat-card class="mat-elevation-z4 ">
                <mat-card-header class="title_center">
                    <mat-card-title>{{zone.data.name}}</mat-card-title>
                </mat-card-header>
                <input type="image" mat-card-image src="{{zone.data.fileUrl}}" (click)="selectZone(zone)">
                <mat-card-content>
                    
                    [{{zone.data.cameras.length}}]       
                    <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
                    <input hidden (change)="onFileSelected($event,zone)" #fileInput type="file">
                    <span class="file-name">{{selectedFile?.name}}</span>
                </mat-card-content>

            </mat-card>
        </div>
    </div>
</div>


