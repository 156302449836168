import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {formatDate} from '@angular/common';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from "@angular/material/paginator";

import { HttpService } from '../../services/http.service';
import { GlobalService } from '../../services/global.service';


export interface TableData {
  cameraID: string;
  fileName: string;
  totalFrames: number;
  link: string;
}


@Component({
  selector: 'app-myvideos',
  templateUrl: './myvideos.component.html',
  styleUrls: ['./myvideos.component.scss']
})
export class MyvideosComponent implements OnInit {

  //dataSource: VideoElement[] = [];
  dataSource = new MatTableDataSource<TableData>();
  emptyData = new MatTableDataSource([{ empty: "row" }]);

  @ViewChildren("tableRows", {read: ElementRef}) tableRows: QueryList<ElementRef>;
  @ViewChild("videoModal") videoModal: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns: string[] = ['location','fileName', 'totalFrames', 'startTime','stopTime'];
  videoUrl: string;
  fileNotFoundError = false;




  constructor(private _http: HttpService, public global: GlobalService,private renderer: Renderer2) {

    console.log("User from global : ", this.global.userInfo)
    console.log("zones from global : ", this.global.zones)
    console.log("cameras from global : ", this.global.cameras)

  }

  ngOnInit(): void {

    let videospath = "videoSites/" + this.global.userInfo.data["siteID"] + "/Videos";
    const format = 'dd/MM/yyyy  h:mm:ss';

    this._http.getFilterCollectionData(videospath, 'userID', this.global.userInfo.data["userID"]).then(async (snapshot: any) => {

      let item: any;
      let camera: any;

      snapshot.forEach(doc => {
        item = { id: doc.id, data: doc.data() }
        
        camera = this.global.getCamerabyID(item.data.cameraID)
        console.log("camera by ID ",camera.location);
        console.log(item.data.stopTime.toDate())

        let dataItem = {
          location: camera.location,
          cameraID: item.data.cameraID,
          fileName: item.data.fileName,
          totalFrames: item.data.totalFrames,
          startTime: formatDate(item.data.startTime.toDate(), format || 'medium', 'en-US'),
          stopTime: formatDate(item.data.stopTime.toDate(), format || 'medium', 'en-US'),
          link: item.data.RTSP,
        }

    
          this.dataSource.data.push(dataItem)


        

      });

      console.log("Data = ", this.dataSource)
      this.dataSource.data = this.dataSource.data;
      //this.refresh();
    });


  }

  async onClickFile(fileName: string) {
    // Open the popup...
    this.renderer.setStyle(this.videoModal.nativeElement, "display", "block");
    this.renderer.addClass(this.videoModal.nativeElement, "show");
    this.renderer.setStyle(
      this.videoModal.nativeElement,
      "background-color",
      "rgb(123 123 123 / 41%)"
    );

    try {
      this.videoUrl = await this._http.getFileUrl(
        `videos/${fileName}`
      );
    } catch (error) {
      this.fileNotFoundError = true;
      console.log(error);
    }
  }

  
  closeModal(element: HTMLDivElement) {
    this.renderer.removeStyle(element, "display");
    this.renderer.removeClass(element, "show");
    this.renderer.removeStyle(element, "background-color");
    this.videoUrl = undefined;
    this.fileNotFoundError = false;

  }


  backdropClicked(event: Event, element: HTMLDivElement) {
    if (
      (event.target as any).id === "videoModal" ||
      (event.target as any).id === "exportExcel"
    )
      this.closeModal(element);
  }


  clickTableRow(index: number, data) {

   console.log(index)

    // Do Nothing if emptyData dataTableSource in use...
    if (data.hasOwnProperty("empty")) return;

    this.tableRows.forEach((eachElementRef) =>
      this.renderer.removeClass(eachElementRef.nativeElement, "highlight-row")
    );
    const selectedRow = this.tableRows.toArray()[index];
    this.renderer.addClass(selectedRow.nativeElement, "highlight-row");
  }



}
