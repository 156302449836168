<mat-card>

    <div #videoContainer id="videoContainer" align="center">
        <!-- <canvas #canvas id="canvas" style="display: block; width: 80%;border: 1;"></canvas>      -->
    </div>

</mat-card>


<div class="content">
    <div fxLayout="row wrap" fxLayoutGap="16px grid" class="card-container">
        <div fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="33%" *ngFor="let num of [1,2,3,4,5,6,7]" class="onecard">
            <mat-card class="mat-elevation-z4">
                <mat-card-header>
                    <mat-card-title>Himalayan Peaks</mat-card-title>
                </mat-card-header>
                 <img mat-card-image src="assets/images/carmel.JPG" > 
                <mat-card-content>
                    <p>
                        The Himalayas is a mountain range in Asia.
                    </p>
                </mat-card-content>

            </mat-card>
        </div>
    </div>
</div>

