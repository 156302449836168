import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, finalize } from "rxjs/operators";
import { Observable } from "rxjs";

import firebase from 'firebase/app'
//import * as admin from 'firebase-admin';

import 'firebase/storage';
import "firebase/auth";
import "firebase/firestore";

import { environment } from 'src/environments/environment';


export class DataUpdateEvent {
  data: any;
  changeType: string;
}



@Injectable({
  providedIn: 'root'
})

export class HttpService {

  db = firebase.firestore();
  st = firebase.storage();

  selectedFile: File = null;
  fb;
  downloadURL: Observable<string>;

  onTestDataChange: EventEmitter<DataUpdateEvent> = new EventEmitter<DataUpdateEvent>();

  constructor(private _http: HttpClient, private afs: AngularFirestore, public afAuth: AngularFireAuth, private storage: AngularFireStorage) { 
    
    this.afAuth.signInWithEmailAndPassword("beni@mes-ltd.co.il", "123456");

    

        this.db.collection("Videos").onSnapshot(querySnapshot => {
          querySnapshot.docChanges().forEach(change => {
            this.onTestDataChange.emit({ data: change.doc.data(), changeType: change.type });
          });

        });
    




  }



  // ====================================================================================================
  // Global and Generic functions
  // ====================================================================================================
  public getCollectionList(collectionpath) {

    // query => {property, value}   i.e. {property: "age"}
    const markers: any = [];

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).get().subscribe(snapshot => {
        snapshot.forEach(doc => {
          markers.push({ id: doc.id, data: doc.data() });

        });
        //return markers;
        res(markers);
        console.log('success')
      })
    })
  }


  public getCollectionData(collectionpath, docid) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(docid).get().subscribe((doc) => {
        if (doc.exists) {
          res({ id: doc.id, data: doc.data() });
        }
        else {
          console.log("No such document!");
          res({ id: undefined, data: undefined });
        }
      });
    });

  }

  public getFilterCollectionData(collectionpath, testfield,pvalue) {

    return new Promise<any>((res, rej) => {
      this.db.collection(collectionpath).where(testfield, "==", pvalue).get().then((snapshot) => {
        if (snapshot.empty == false) {
          res(snapshot);
        }
        else {
          console.log("No such document!");
          res({ id: undefined, data: undefined });
        }
      });
    });

  }


  public addCollectionItam(collectionpath, data) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).add(data)
        .then((doc) => {
          console.log({ "id": doc.id, "data": data });
          res(doc);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });


  }

  public updateCollectionData(collectionpath, Itemid, itemData) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(Itemid).set(itemData, { merge: true })
        .then((docRef) => {
          console.log({ "collectionPath": collectionpath, "id": Itemid, "data": itemData });
          res({ id: Itemid, data: itemData });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });
  }

  public deleteCollectionItem(collectionpath, Itemid) {
    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(Itemid).delete()
        .then((docRef) => {
          console.log({ "collectionPath": collectionpath, "id": Itemid });
          res(docRef);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });

  }

  public deleteFile(videoName, path: string = 'MediafromTests/Videos/') {

    return new Promise<any>((res, rej) => {
      this.st.ref(path + videoName).delete().then(() => {

        console.log("file was deleted")
      })
        .catch((err) => {
          console.log("error ", err)
        })

    })

    console.log("file to delete", path + videoName)
    //
  }

  public getTotalNumber(collection) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collection).get()
        .subscribe(async function (querySnapshot) {
          let totalRequests = querySnapshot.docs.length;
          res({ number: totalRequests });
        });
    });

  }


  public getFileUrl(fileName) {
   
    return new Promise<any>((res, rej) => {
      var ref = firebase.storage().ref().child(`${fileName}`);
      console.log(ref)
      ref.getDownloadURL().then((url) => {
        res(url);
      }).catch(err => {
        rej(err);
      });
    });
  }

  onFileSelected(event,siteID,zoneID) {
    var n = Date.now();
    const file = event.target.files[0];
    const filePath = `images/` + siteID + `/image_${n}_`+file.name;
    
    console.log("upload the file : ",filePath,file)

    
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.fb = url;
            }
            console.log(this.fb);

            this.updateCollectionData("videoSites/"+siteID+"/Zones/",zoneID,{fileName: file.name, fileUrl: url})
          });
        })
      )
      .subscribe(url => {
        if (url) {
          console.log(url);
        }
      });

      
  }

  // ====================================================================================================
  // End of Global and Generic functions
  // ====================================================================================================


public startRecord(data) {

  let url = "https://rtsprecorder-na6h4zb5uq-uc.a.run.app/start" 
  //let url = "http://localhost:8080/start"
  return this._http.post(url, data);

}










}
