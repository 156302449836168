
<div class=" text-center ">



    <div class="flexbox-parent center">
        <div layout-align="center center">

            <mat-form-field appearance="fill" layout-align="center center">
                <mat-label>Select</mat-label>
                <mat-select (selectionChange)="SelectCamera($event)">
                    <mat-option *ngFor="let court of courts" [value]="court" >
                        {{court.name}}
                    </mat-option>
                </mat-select>

            </mat-form-field>

        </div>
    </div>
    

    <p #videoContainer id="videoContainer" align="center">
            <canvas #canvas id="canvas" style="display: block; width: 60%;border: 1;"></canvas>       
    </p>

    <p>&nbsp;</p>

    <button type="button" (click)='StartRecord()' class="btn btn-primary btna" [disabled]="isDisabled"
    [ngClass]="{'green' : toggle, 'red': !toggle}"
    
    >
        start recording
    </button>


</div>