import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component'
import { LoginComponent } from './login/login.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',           
  },
  {
    path: 'login',
    component: LoginComponent,           
  },
  {
    path: 'home',
    component: HomeComponent,           
  },  
  {
    path: 'view',
    loadChildren: () =>
      import('./view/view.module').then((m) => m.ViewModule),
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})





export class AppRoutingModule { }
